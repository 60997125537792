import React from "react";
import "./projectPreview.css";
import "./projectPreviewMobile.css";

import Image from "../../../components/image/image";

class ProjectPreview extends React.Component {
  render() {
    return (
      <div
        className={`${
          this.props.isHovered && !this.props.isScrolling
            ? "projectPreview hover"
            : "projectPreview"
        } ${this.props.isClone ? "clone" : ""}`}
        id={this.props.project.fields.slug}
        onMouseEnter={this.props.onMouseEnterHandler}
        onMouseLeave={this.props.onMouseLeaveHandler}
        onClick={this.props.onClickHandler}
      >
        <Image
          src={this.props.project.fields.previewImage.fields.file.url}
          handleImageCreate={this.props.handleProjectPreviewCreate}
          handleImageLoaded={this.props.handleProjectPreviewLoad}
        />
        {this.props.project.fields.previewImageMovie !== undefined && (
          <video
            className="hoverVideo"
            autoPlay
            loop
            muted
            playsInline
            src={this.props.project.fields.previewImageMovie.fields.file.url}
          />
        )}
        <div className="backgroundFade" />
        <div className="title-container">
          <p className="title display-font">
            {this.props.project.fields.title}
          </p>
          <p className="sub-title body-font">
            {this.props.project.fields.subTitle}
          </p>
        </div>
      </div>
    );
  }
}
export default ProjectPreview;
