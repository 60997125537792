import React from "react";
import { navigate } from "@reach/router";

import ProjectPreview from "./projectPreview";

class ProjectPreviewContainer extends React.Component {
  constructor(props) {
    super(props);
    this.project = this.props.project;

    this.state = {
      loaded: false,
      isHovered: false
    };

    // handle synthetic events
    this.onMouseEnterHandler = this.onMouseEnterHandler.bind(this);
    this.onMouseLeaveHandler = this.onMouseLeaveHandler.bind(this);
    this.onClickHandler = this.onClickHandler.bind(this);
    this.onProjectPreviewCreated = this.onProjectPreviewCreated.bind(this);
    this.onProjectPreviewLoad = this.onProjectPreviewLoad.bind(this);
  }

  // handle selection states
  onMouseEnterHandler() {
    this.setState({
      isHovered: true
    });
  }

  onMouseLeaveHandler() {
    this.setState({
      isHovered: false
    });
  }

  onClickHandler(event) {
    const projectSlug = event.currentTarget.id;
    // timeout - trigger click animation
    this.props.handleRouteLoad(false, () => {
      window.setTimeout(() => {
        navigate(`/${projectSlug}`);
      }, 310);
    });
  }

  onProjectPreviewCreated() {
    this.props.handleProjectPreviewCreated();
  }

  onProjectPreviewLoad() {
    this.setState({ loaded: true }, () => {
      this.props.handleProjectPreviewLoad();
    });
  }

  render() {
    return (
      <ProjectPreview
        project={this.project}
        onMouseEnterHandler={this.onMouseEnterHandler}
        onMouseLeaveHandler={this.onMouseLeaveHandler}
        onClickHandler={this.onClickHandler}
        isHovered={this.state.isHovered}
        isScrolling={this.props.isScrolling}
        isClone={this.props.isClone}
        handleProjectPreviewCreate={this.onProjectPreviewCreated}
        handleProjectPreviewLoad={this.onProjectPreviewLoad}
      />
    );
  }
}
export default ProjectPreviewContainer;
