import React from "react";
import { RouteData } from "react-static";
import throttle from "lodash.throttle";
import $ from "jquery";
import "jquery-mousewheel";

import "./index.css";
import "./indexMobile.css";

import Biography from "../components/biography/biography";
import Loader from "../components/loader/loader";
import ProjectPreview from "../containers/projects/projectPreview/projectPreviewContainer";

class Projects extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      showLoadingImage: true,
      isScrolling: false,
      projectPreviewsToLoad: 0,
      projectPreviewsLoaded: 0
    };

    // non-managed state for dealing with the dom
    if (typeof window !== "undefined") {
      // browser-only code
      this.doc = window.document;
    }
    // this.clones = this.doc.getElementsByClassName("is-clone");
    this.disableScroll = false;
    this.scrollWidth = 0;
    this.scrollPos = 0;
    this.clonesWidth = 0;
    this.index = 0;

    // bind functions
    this.reCalc = this.reCalc.bind(this);
    this.scrollUpdate = this.scrollUpdate.bind(this);
    this.setScrollPos = this.setScrollPos.bind(this);
    this.getScrollPos = this.getScrollPos.bind(this);
    this.getClonesWidth = this.getClonesWidth.bind(this);
    this.handleMousewheel = this.handleMousewheel.bind(this);
    this.handleRouteLoad = this.handleRouteLoad.bind(this);
    this.renderProjectPreviews = this.renderProjectPreviews.bind(this);
    this.handleProjectPreviewCreated = this.handleProjectPreviewCreated.bind(
      this
    );
    this.handleProjectPreviewLoad = this.handleProjectPreviewLoad.bind(this);
    // dom ref
    this.projectsRef = React.createRef();
    this.startElement = null;
  }

  componentDidMount() {
    // get outside-of-react dom connection to projects slideshow
    this.$projectsRef = $(this.projectsRef.current);
    // get first element in horizontal scrolling array
    this.startElement = this.projectsRef.current.children[0];
    window.requestAnimationFrame(this.reCalc);
    // listen for resize events
    window.addEventListener("resize", this.reCalc);
    // listen for mousewheel events
    $("body, html").on("mousewheel", throttle(this.handleMousewheel));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.reCalc);
    $("body, html").off("mousewheel");
  }

  handleRouteLoad(loaded, callback) {
    this.setState(
      {
        loaded,
        showLoadingImage: false
      },
      callback
    );
  }

  handleProjectPreviewCreated() {
    this.setState(previousState => {
      return { projectPreviewsToLoad: previousState.projectPreviewsToLoad + 1 };
    });
  }

  handleProjectPreviewLoad() {
    this.setState(
      previousState => {
        return {
          projectPreviewsLoaded: previousState.projectPreviewsLoaded + 1
        };
      },
      () => {
        if (
          this.state.projectPreviewsToLoad > 0 &&
          this.state.projectPreviewsToLoad ===
            this.state.projectPreviewsLoaded &&
          this.state.loaded !== true
        ) {
          window.setTimeout(() => {
            this.handleRouteLoad(true);
          }, 500);
        }
      }
    );
  }

  // prevent creating a new function object on every re-render
  renderProjectPreviews(projects) {
    // generate necessary number of clones
    const projectsWithClones = projects.concat(projects.slice(0, 5));
    return projectsWithClones.map((project, index) => {
      return (
        <ProjectPreview
          project={project}
          isClone={index >= projects.length}
          handleRouteLoad={this.handleRouteLoad}
          isScrolling={this.state.isScrolling}
          key={index}
          handleProjectPreviewCreated={this.handleProjectPreviewCreated}
          handleProjectPreviewLoad={this.handleProjectPreviewLoad}
        />
      );
    });
  }

  handleMousewheel(event) {
    //  short-circuit if window is too small - only on desktop version
    if (window.innerWidth < 768) {
      return;
    }
    // prevent vertical scroll
    if (event.deltaY !== 0) {
      event.preventDefault();
    }
    // manually scroll horizontally instead
    this.$projectsRef.scrollLeft(this.$projectsRef.scrollLeft() - event.deltaY);
  }

  getScrollPos() {
    return (
      (this.projectsRef.current.pageXOffset ||
        this.projectsRef.current.scrollLeft) -
      (this.projectsRef.current.clientLeft || 0)
    );
  }

  setScrollPos(pos) {
    this.projectsRef.current.scrollLeft = pos;
  }

  getClonesWidth() {
    return this.startElement.clientWidth * 5;
  }

  reCalc() {
    if (this.projectsRef.current === null) {
      return;
    }
    this.scrollPos = this.getScrollPos();
    this.scrollWidth = this.projectsRef.current.scrollWidth - 1;
    this.clonesWidth = this.getClonesWidth();

    if (this.scrollPos <= 0) {
      this.setScrollPos(1);
    }
  }

  scrollUpdate() {
    // handle turning off hover effect
    if (this.state.isScrolling === false) {
      //   set scrolling to true
      this.setState(
        {
          isScrolling: true
        },
        () => {
          window.setTimeout(() => {
            this.setState({
              isScrolling: false
            });
          }, 500);
        }
      );
    }

    //  short-circuit if window is too small - only infinite scroll on desktop version
    if (window.innerWidth < 768) {
      return;
    }
    // handle infinite looping
    if (this.disableScroll === false) {
      this.scrollPos = this.getScrollPos();
      if (this.clonesWidth + this.scrollPos >= this.scrollWidth) {
        // Scroll to the left when you’ve reached the far right
        this.setScrollPos(1); // Scroll 1 pixel to allow scrolling backwards.
        this.disableScroll = true;
      } else if (this.scrollPos <= 0) {
        // Scroll to the right when you reach the far left.
        this.setScrollPos(this.scrollWidth - this.clonesWidth);
        this.disableScroll = true;
      }

      if (this.disableScroll) {
        // Disable scroll-jumping for a short time to avoid flickering.
        window.setTimeout(() => {
          this.disableScroll = false;
        }, 40);
      }
    }
  }

  render() {
    return (
      <RouteData>
        {({ projects }) => (
          <div>
            <Biography />
            <Loader
              loaded={this.state.loaded}
              showLoadingImage={this.state.showLoadingImage}
            />
            <div
              className="projects js-loop"
              onScroll={throttle(this.scrollUpdate)}
              ref={this.projectsRef}
            >
              {this.renderProjectPreviews(projects)}
            </div>
          </div>
        )}
      </RouteData>
    );
  }
}

export default Projects;
