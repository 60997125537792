import React from "react";

import Image from "../image/image";
// transition - 'wrapper' or entire object should start at opacity: 1, then 'load' then fade out.
// when removed from DOM ( on transition ) it should fade back in.

import "./loader.css";

import LoadingGif from "./assets/Loader_03_optimized.gif";

class Loader extends React.Component {
  render() {
    return (
      <div className={this.props.loaded ? "wrapper loaded" : "wrapper"}>
        {this.props.showLoadingImage === true ? (
          <div className="imageContainer">
            <Image src={LoadingGif} />
          </div>
        ) : (
          <div className="imageContainer" />
        )}
      </div>
    );
  }
}
export default Loader;
