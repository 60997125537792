import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

import "./biographyToggle.css";

class BiographyToggle extends React.Component {
  constructor(props) {
    super(props);

    // This binding is necessary to make `this` work in the callback
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    e.preventDefault();
    //   pass click event upwards so that it can be passed down to biography
    this.props.handleBiographyToggle();
  }

  render() {
    return (
      <button
        className="biographyToggle biographyButton"
        onClick={this.handleClick}
      >
        BIO
      </button>
    );
  }
}
export default BiographyToggle;
