import React from "react";
import { Link } from "@reach/router";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faTwitter,
  faVimeo,
  faBehance,
  faLinkedin
} from "@fortawesome/free-brands-svg-icons";

import "./biography.css";
import "./biographyMobile.css";

import BiographyToggle from "./biographyToggle";

export default class Biography extends React.Component {
  constructor(props) {
    super(props);
    this.state = { open: this.props.open };

    // This binding is necessary to make `this` work in the callback
    this.handleBiographyToggle = this.handleBiographyToggle.bind(this);
  }

  // handle state of biography being toggled
  handleBiographyToggle() {
    this.setState(state => ({
      open: !state.open
    }));
  }

  render() {
    return (
      <div>
        <div className={this.state.open ? "biography open" : "biography"}>
          <button
            className="biographyClose"
            onClick={this.handleBiographyToggle}
          >
            X
          </button>
          <div className="colophon body-font">
            <div className="links">
              <ul>
                <li>
                  <a
                    href="https://twitter.com/_nickemiller"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faTwitter} />
                  </a>
                </li>
                <li>
                  <a
                    href="https://vimeo.com/nickemiller"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faVimeo} />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.behance.net/nickemiller"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faBehance} />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/in/nick-miller-8bb159a5/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faLinkedin} />
                  </a>
                </li>
              </ul>
            </div>
            <div className="credit">
              <a
                href="http://ianglover.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Site Development: Ian Glover
              </a>
            </div>
            <div className="copyright">&copy; Nick Miller 2019</div>
          </div>
          <div className="contact">
            <p className="display-font oversized-header">BIO</p>
            <p className="body-font">
              <a href="mailto:nick@nickmiller.tv">nick@nickmiller.tv</a>
            </p>
            <p className="body-font">415.342.7805</p>
          </div>
          <div className="story body-font">
            <p>
              NICHOLAS MILLER thinks like a filmmaker and problem solves like a
              designer. After an internship at Elastic, he graduated film school
              & moved to New York where he honed his design skills at Gretel,
              working on award-winning projects for the likes of Apple, Nike,
              Paramount, and The New York Times. He is now freelancing locally
              in New York & Los Angeles while expanding his skills as a designer
              & filmmaker. He is determined to bring his cinematic sensibilities
              to motion graphics.
            </p>
          </div>
        </div>
        <BiographyToggle handleBiographyToggle={this.handleBiographyToggle} />
      </div>
    );
  }
}
