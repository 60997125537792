import React, { Component } from "react";

import "./image.css";

export default class Image extends Component {
  constructor(props) {
    super(props);

    if (this.props.handleImageCreate !== undefined) {
      this.props.handleImageCreate();
    }

    this.state = {
      loaded: false
    };

    this.handleImageLoaded = this.handleImageLoaded.bind(this);
    this.handleImageError = this.handleImageError.bind(this);
    this.image = React.createRef();
  }

  componentDidMount() {
    const img = this.image.current;
    if (img && img.complete) {
      this.handleImageLoaded();
    }
  }

  handleImageLoaded() {
    if (!this.state.loaded) {
      this.setState({ loaded: true }, () => {
        if (this.props.handleImageLoaded !== undefined) {
          this.props.handleImageLoaded();
        }
      });
    }
  }

  handleImageError() {
    this.setState({ loaded: false });
  }

  render() {
    return (
      <img
        className={`image ${this.state.loaded === true ? "loaded" : ""} ${this
          .props.className || ""}`}
        src={this.props.src}
        alt={this.props.alt || ""}
        onLoad={this.handleImageLoaded}
        onError={this.handleImageError}
        ref={this.image}
      />
    );
  }
}

// ideas:
// it takes an init function - so as soon as its rendered, it can tell a counter to increase for instance
// it takes a callback - so when its loaded, it can call a function passed to it. the function might add 1 to a state tracking
